
import BaseSelect from '@/components/common/base-select/base-select'
// import { validbusinessNo } from '@/utils/validate'
import { getDictLists } from '@/filters/fromDict'
export const fromConfig = (content) => {
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      // rules: [{ required: false, validator: validbusinessNo, trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      }
    },
    {
      label: '供应商名称',
      prop: 'gysId',
      tag: BaseSelect,
      attrs: {
        filterable: true,
        placeholder: '请选择',
        options: content.gysData,
        selectedField: ['keyId', 'enterpriseName']
      }
    },
    {
      label: '审核状态',
      prop: 'reviewStatus',
      attrs: {
        placeholder: '请选择',
        options: getDictLists('REVIEW_STATUS'),
        selectedField: ['dictId', 'dictName']
      },
      tag: BaseSelect
    },

    {
      label: '文件制作状态',
      prop: 'fileMakeStatus',
      attrs: {
        placeholder: '请选择',
        options: getDictLists('FILE_MAKE_STATUS'),
        selectedField: ['dictId', 'dictName']
      },
      tag: BaseSelect,
      isHidden: content.fold
    }
  ]
}
export const columnsConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '业务编号',
      prop: 'businessNo',
      'show-overflow-tooltip': true
    },
    {
      label: '供应商名称',
      prop: 'gysCompanyName',
      'show-overflow-tooltip': true,
      align: 'left'
    },
    {
      label: '融资金额(元)',
      prop: 'financeAmount',
      'show-overflow-tooltip': true,
      align: 'right',
      formatter: row => {
        return (row.financeAmount ? row.financeAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '审核状态',
      prop: 'reviewStatus',
      'show-overflow-tooltip': true,
      formatter: row => {
        const [obj] = getDictLists('REVIEW_STATUS').filter(item => item.dictId === row.reviewStatus.toString())
        return obj ? obj.dictName : '-'
      }
    },
    {
      label: '文件制作状态',
      prop: 'fileMakeName',
      'show-overflow-tooltip': true
    },
    {
      label: '操作',
      prop: 'action',
      width: '155px',
      fixed: 'right'
    }
  ]
}
