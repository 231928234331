<template>
  <!-- 尽调报告制作管理 -->
  <div class="contract-file-manage-list">
    <!-- 查询表单 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="resetFrom"
      ref="formData"
    >
    <div slot="btn_after">
      <base-button icon='iconfont iconzhankai' class="foldBtn" :class="{PutawayBtn:fold}" type='default' @click="fold=!fold" />
    </div>
    </base-form>

    <!-- 数据表格 -->
    <base-table
      class="main-page-table"
      :columns="columns"
      :showPage="true"
      :queryParas.sync="queryParas"
      :tableAttrs="{
        stripe: true,
      }"
      :loadCount="loadCount"
      :api="api"
      :getApi="'getPage'"
      ref="tableData"
      :webPage="false"
      :dataSource.sync="tableData"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <!-- 按钮组 -->
          <icon-button
          content="查看"
          icon="iconfont iconchakan"
          @click="info(scope.row)"

        />
         <!-- v-if="scope.row.reviewStatus === 10||scope.row.reviewStatus === 20||scope.row.reviewStatus === 30||scope.row.fileMakeStatus===20||scope.row.fileMakeStatus===50||scope.row.fileMakeStatus===10" -->
        <icon-button
          @click="edit(scope.row)"
          content="制作"
          icon="iconfont iconbianji3"
           v-if="(scope.row.reviewStatus === 40||scope.row.reviewStatus === 50||scope.row.fileMakeStatus=== 10 || scope.row.fileMakeStatus=== 20)&&(scope.row.reviewStatus!==90&&scope.row.reviewStatus!==80)"
        />
        <!-- 20220525 岳老板要求更改 -->
 <icon-button
          content="预览"
          icon="iconfont iconyulan"
          @click="preview(scope.row)"
          v-if=" scope.row.fileMakeStatus === 30&&(scope.row.reviewStatus!=='40' ||scope.row.reviewStatus!=='90')"
        />
        <!-- <icon-button
          content="撤回"
          icon="iconfont iconchehui"
          @click="cancel(scope.row)"
           v-if="scope.row.reviewStatus === 10&&scope.row.fileMakeStatus===30"
        /> -->
      </template>
    </base-table>
 <pre-view :fileId="fileData.fileId" :isOpen='true' :fileType="fileData.fileFileSuffix" :count="count" />

  </div>
</template>
<script>
import PreView from '@/components/pre-view/pre-view.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import baseForm from '@/components/common/base-form/base-form.vue'
import { fromConfig, columnsConfig } from './utils/config.js'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { progressApi } from '@/api/businessApi'
import { supplierApi } from '@/api/companyApi'
import Storage from '@/utils/storage'
export default {
  name: 'contractFileManage',
  components: { baseForm, BaseTable, IconButton, BaseButton, PreView },
  data () {
    return {
      count: 0,
      fileData: {},
      fold: false,
      visible: false,
      loadCount: 0,
      queryParas: {
        pageSize: 10, // 每页显示的数据
        pageIndex: 1,
        processStatus: 'DUESUR'

      },
      disabled: false,
      tableData: [{}, {}],
      gysData: []
    }
  },
  computed: {
    api () {
      return progressApi
    },
    formConfig () {
      return fromConfig(this)
    },
    columns () {
      return columnsConfig(this)
    }
  },
  mounted () {
    this.handleFilter()
    this.gysList()
  },
  activated () {
    this.handleFilter()
    this.gysList()
  },
  methods: {
    // 预览
    preview (data) {
      if (data.fileId && data.fileName) {
        this.fileData.fileId = data.fileId
        this.fileData.fileFileSuffix = data.fileName.substr(data.fileName.lastIndexOf('.') + 1, 4)
        this.count++
      } else {
        this.warning('文件信息有误，稍后重试')
      }
    },
    // 撤回
    cancel (row) {
      this.$stateConfirm({
        title: '提示',
        message: '确认撤回吗？',
        show: true,
        type: 'warning'
      }).then(() => {
        this.api.cancel({ businessId: row.keyId, processStatus: 'DUESUR' }).then(res => {
          if (res.success) {
            this.success('撤回成功')
            this.handleFilter()
          }
        })
      }).catch(() => {})
    },

    // 查看详情
    info (row) {
      row.businessId = row.keyId
      Storage.setLocal('contractInfo', row)
      this.$router.push({ path: '/business/reportProductionInfo', query: { businessId: row.keyId } })
    },
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then((res) => {
        if (res.success) {
          this.gysData = res.data
        }
      })
    },
    /**
     * 查询
     */
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
    },
    /**
     * 重置表单
     */
    resetFrom () {
      this.queryParas = {
        pageSize: 10, // 每页显示的数据
        pageIndex: 1,
        processStatus: 'DUESUR'
      }
    },
    // 查看详情
    edit (data) {
      data.businessId = data.keyId
      Storage.setLocal('contractInfo', data)
      this.api.getCheckForbiddenOrNot({ businessId: data.keyId }).then(res => {
        if (res.data.status) {
          this.$router.push({ path: '/business/report-production-make/makeProcess', query: { businessId: data.keyId } })
        } else {
          this.warning(res.data.errorMsg)
        }
      })
    }

  }
}
</script>
